<template>
  <el-card>
    <div slot="header">订单配置</div>
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      size="medium"
      label-width="220px"
    >
      <el-form-item label="订单未付款取消订单时间" prop="payTimeout">
        <el-input-number style="width: 150px;" :min="20" v-model="ruleForm.payTimeout"></el-input-number>分钟
      </el-form-item>
      <el-form-item label="顾客评价自动结束时间" prop="evaluationTimeout">
        <el-input-number style="width: 150px;" :min="1" :max="999" v-model="ruleForm.evaluationTimeout"></el-input-number>天
      </el-form-item>
      <el-form-item label="确认收货结束时间" prop="confirmTimeout">
        <el-input-number style="width: 150px;" :min="1" :max="999" v-model="ruleForm.confirmTimeout"></el-input-number>天
      </el-form-item>
      <el-form-item label="查询物流" prop="logisticsQueryEnable">
        <el-switch v-model="ruleForm.logisticsQueryEnable"></el-switch>
      </el-form-item>
      <el-form-item v-if="ruleForm.logisticsQueryEnable" style="width: 600px;" label="身份标识码" prop="logisticsQueryAuth">
        <el-input :maxlength="100" v-model="ruleForm.logisticsQueryAuth"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">
          保存
        </el-button>
      </el-form-item>
    </el-form>
  </el-card>
</template>
<script>
import {getRsaCode} from '../../../utils/utils'
export default {
  name: 'ParamsConfig',
  components: {},
  data () {
    return {
      originLogisticsQueryAuth: '',
      ruleForm: {
        logisticsQueryAuth: '',
        payTimeout: 0,
        evaluationTimeout: 0,
        confirmTimeout: 0,
        logisticsQueryEnable: false
      },
      rules: {
        payTimeout: [
          { required: true, message: '请输入订单未付款取消订单时间', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              if (!value) {
                callback(new Error('请输入订单未付款取消订单时间'))
              } else if (!/^\d+$/.test(value)) {
                callback(new Error('只能输入数字'))
              } else {
                callback()
              }
            },
            trigger: 'blur'
          }
        ],
        evaluationTimeout: [
          { required: true, message: '请输入顾客评价自动结束时间', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              if (!value) {
                callback(new Error('请输入顾客评价自动结束时间'))
              } else if (!/^\d+$/.test(value)) {
                callback(new Error('只能输入数字'))
              } else {
                callback()
              }
            },
            trigger: 'blur'
          }
        ],
        confirmTimeout: [
          {
            required: true,
            message: '请输入确认收货结束时间',
            trigger: 'blur'
          },
          {
            validator: (rule, value, callback) => {
              if (!value) {
                callback(new Error('请输入确认收货结束时间'))
              } else if (!/^\d+$/.test(value)) {
                callback(new Error('只能输入数字'))
              } else {
                callback()
              }
            },
            trigger: 'blur'
          }
        ],
        logisticsQueryAuth: [
          {
            required: true,
            message: '请输入身份标识码',
            trigger: 'blur'
          },
        ]
      },
      orderConfig: {}
    }
  },
  created () {
    this.handleGetOrderConfig()
  },
  methods: {
    handleGetOrderConfig () {
      this.$api.shopManageApi.getOrderConfig().then(res => {
        this.ruleForm = Object.assign(this.ruleForm, res.data)
        this.originLogisticsQueryAuth = res.data.logisticsQueryAuth
      })
    },
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const { logisticsQueryAuth, logisticsQueryEnable } = this.ruleForm
          if (!logisticsQueryEnable) {
            delete this.ruleForm.logisticsQueryAuth
          } else {
            if (logisticsQueryAuth == this.originLogisticsQueryAuth) {
              delete this.ruleForm.logisticsQueryAuth
            } else {
              this.ruleForm.logisticsQueryAuth = getRsaCode(this.ruleForm.logisticsQueryAuth)
            }
          }
          this.$api.shopManageApi.updateOrderConfig(this.ruleForm).then(res => {
            if (res.code == 200) {
              this.$message.success('修改成功')
              this.handleGetOrderConfig()
            }
          })
          console.log('ruleForm', this.ruleForm);
        } else {
          return false
        }
      })
    }
  }
}
</script>
<style lang="less" scoped></style>
